import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import React, { useState } from "react";

import {
  DeleteVideosAPI,
  FetchVideosAPI,
  PostVideosAPI,
} from "./VideosQueries";

import CommonDialog from "components/CommonDialog";
import * as yup from "yup";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomTextField } from "components";
const videoFormSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  url: yup
    .string()
    .required("url is required")
    .matches(
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
      "Enter correct url!"
    ),
});

const VideoPage = () => {
  const { data: videoList } = FetchVideosAPI();
  const [openDialog, setOpenDialog] = useState(false);
  const { mutate: addVideoQuery } = PostVideosAPI();
  const { mutate: deleteVideoQuery } = DeleteVideosAPI();

  const handleClose = () => {
    reset();
    setOpenDialog(false);
  };
  const handleOpen = () => {
    setOpenDialog(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(videoFormSchema),
    defaultValues: {
      title: "",
      url: "",
    },
  });
  const { ref: refTitle, ...RegisterTitle } = register("title");
  const { ref: refURl, ...RegisterUrl } = register("url");
  const [videoType, setVideoType] = useState("tutorial");
  const handleSubmitForm = (data) => {
    addVideoQuery({ ...data, video_type: videoType });
    console.log(data);
    handleClose();
  };
  const handleDeleteVideo = (id) => {
    deleteVideoQuery(id);
  };
  const AddVideoForm = () => {
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container direction="column">
          <Grid item>
            <CustomTextField
              required
              label="Title"
              type="text"
              inputRef={refTitle}
              {...RegisterTitle}
              error={Boolean(errors.title)}
              helperText={errors.title && errors.title.message}
              autoComplete="title"
            />
          </Grid>
          <Grid item>
            <CustomTextField
              required
              label="Url"
              type="text"
              inputRef={refURl}
              {...RegisterUrl}
              error={Boolean(errors.url)}
              helperText={errors.url && errors.url.message}
              autoComplete="url"
            />
          </Grid>
          <Grid item>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                row
                aria-labelledby="energy-question-1"
                name="energy-question-1"
                value={videoType}
                defaultValue="tutorial"
                onChange={(e) => setVideoType(e.target.value)}
              >
                <Grid container alignItems="center">
                  <Grid item>
                    <FormControlLabel
                      value="tutorial"
                      control={<Radio />}
                      labelPlacement="start"
                      label="Tutorials"
                      sx={{
                        color: "#000",
                        "& .MuiFormControlLabel-label": {
                          color: "#000",
                          fontSize: "15px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value="learn-more"
                      control={<Radio />}
                      labelPlacement="start"
                      label="Learn More"
                      sx={{
                        color: "#000",
                        "& .MuiFormControlLabel-label": {
                          color: "#000",
                          fontSize: "15px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container item justifyContent="center" alignItems="center">
            <Button
              sx={{
                backgroundColor: "rgb(26, 46, 70) !important",
                marginTop: 4,
                marginBottom: 2,
              }}
              type="submit"
              fullWidth
              variant="contained"
            >
              Add Video
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };

  return (
    <>
      <Grid container direction="column" className="max-width !my-4 gap-10">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={6}>
            {/* <TableSearch
              placeholder={"Video"}
              // value={debouncedSearchKeyword}
              // onChange={handleSearchUpdate}
            /> */}
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              color="primary"
              className="!bg-[rgb(26, 46, 70)]"
              sx={{ backgroundColor: "rgb(26, 46, 70) !important" }}
              variant="contained"
              onClick={handleOpen}
            >
              Add Video
            </Button>
          </Grid>
        </Grid>

        <Grid container className="-mt-12" columnSpacing={4} rowSpacing={6}>
          {videoList?.data?.map((item) => {
            const videoIndex = item?.videoUrl?.split("/");
            const videoID = videoIndex[videoIndex?.length - 1];
            const thumbnail = `https://img.youtube.com/vi/${videoID}/hqdefault.jpg`;
            console.log(item);
            return (
              <Grid item key={item.label} xs={12} md={4} lg={3} className="">
                <div className="relative">
                  <img src={thumbnail} className="!w-full " loading="lazy" />
                  <Typography className="!relative !-mt-[4px] cursor-default !bg-pwave-main !py-2 !text-center !text-white">
                    {`Video 1: ${item.title}`}
                  </Typography>
                  <DeleteOutlineIcon
                    onClick={() => handleDeleteVideo(item.videoId)}
                    className="!absolute bottom-[5px] right-[2px] cursor-pointer text-white"
                  />{" "}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <CommonDialog
        maxWidth={"xs"}
        onClose={handleClose}
        title={"Add New Video"}
        open={openDialog}
      >
        <AddVideoForm />
      </CommonDialog>
    </>
  );
};

export default VideoPage;
