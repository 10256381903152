import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import RouteConstants from "routes/RouteConstants";
import { useAPI } from "services";
import { useToast } from "utils/CustomHooks";

/* Helper Functions */
const LoginUser = (body) => {
  const method = "POST";
  const url = `api/auth/admin/login`;
  return useAPI({
    method,
    url,
    body,
  });
};
const LogoutUser = () => {
  const method = "POST";
  const url = `api/admin/logout`;
  return useAPI({
    method,
    url,
  });
};
const FetchUserDetails = () => {
  const method = "GET";
  const url = `api/auth/me`;
  return useAPI({ method, url });
};
const UploadUserAvatar = (body) => {
  const method = "POST";
  const url = `api/admin/upload/avatar`;
  const formData = new FormData();
  formData.append("avatar", body?.file);
  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};
const GetNotifications = () => {
  const method = "GET";
  const url = `api/admin/notification`;
  return useAPI({ method, url });
};
const UpdateProfile = (body) => {
  const method = "PATCH";
  const url = `api/users/update-profile`;
  return useAPI({ method, url, body });
};
export const UpdateProfileQuery = (payload) => {
  const queryClient = useQueryClient();
  return useMutation(UpdateProfile, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("GET_LOGGED_IN_USER_DETAILS");
      queryClient.invalidateQueries("GET_PROFILE_DETAILS");
    },
    onError: ErrorHandler,
  });
};
const ReadNotification = (body) => {
  const method = "PUT";
  const url = `api/admin/notification`;
  return useAPI({
    method,
    url,
  });
};

/* query functions */
export const LoginUserQuery = (body) => {
  const responseToast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(LoginUser, {
    onSuccess: (response) => {
      localStorage.clear();
      console.log(response);
      queryClient.invalidateQueries();
      localStorage.setItem("login", JSON.stringify(true));
      localStorage.setItem("token", response.data.access_token.token);
      localStorage.setItem("expires", response.data.access_token.expires);
      localStorage.setItem(
        "refresh_token",
        response.data.access_token.refresh_token
      );
      responseToast.toastSuccess(
        response.description || "Logged In Successfully"
      );
      navigate(RouteConstants.REDIRECTOR);
    },
    onError: ErrorHandler,
  });
};
export const LogoutUserQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(LogoutUser, {
    onSuccess: (response) => {
      localStorage.clear();
      queryClient.invalidateQueries();
      responseToast.toastSuccess(
        response.description || "Logged Out Successfully"
      );
    },
  });
};
export const FetchUserDetailsQuery = () => {
  return useQuery("GET_LOGGED_IN_USER_DETAILS", FetchUserDetails, {
    enabled: Boolean(localStorage.getItem("token")),
  });
};
export const UploadUserAvatarQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(UploadUserAvatar, {
    onSuccess: (response) => {
      console.log("UploadUserAvatar--response", response);
      queryClient.invalidateQueries("GET_LOGGED_IN_USER_DETAILS");
      queryClient.invalidateQueries("GET_PROFILE_DETAILS");
    },
    onError: ErrorHandler,
  });
};
export const GetNotificationsQuery = () => {
  return useQuery("GET_NOTIFICATIONS", GetNotifications, {
    enabled: true,
  });
};
export const ReadNotificationQuery = (body) => {
  const queryClient = useQueryClient();
  return useMutation(ReadNotification, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("GET_NOTIFICATIONS");
    },
    onError: ErrorHandler,
  });
};

const ErrorHandler = (error) => {
  const responseToast = useToast();
  if (error?.errors) {
    responseToast.toastError("Something went wrong!", error.errors);
  } else if (error?.message) {
    responseToast.toastError(error.message);
  }
};
