import { useState, forwardRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "components";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const CustomTextField = forwardRef((props, ref) => {
  const {
    label,
    variant,
    type,
    error,
    helperText,
    className,
    inputRef,
    required,
    fullWidth,
    size,
    disabled,
    labelColor,
    startAdornment,
    endAdornment,
    controlled,
    ...restOfTextFieldProps
  } = props;

  //states
  const [showPassword, setShowPassword] = useState(false);

  //Handlers
  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => window.location.reload()}
    >
      <FormControl
        className={`${className} ${fullWidth ? "w-full" : ""}`}
        variant={variant}
        margin="dense"
        error={Boolean(error)}
      >
        <InputLabel
          required={required}
          disabled={disabled}
          /* color={labelColor} */
          className="text-base text-hb_blue-350"
          style={
            endAdornment || startAdornment
              ? { marginTop: "4px" }
              : { marginTop: "-4px" }
          }
          /**
              https://github.com/mui-org/material-ui/pull/12769/commits/567cc41939512a4240ad283b5c12c265495fec65#
          */
          {...(type === "number" &&
            controlled &&
            typeof restOfTextFieldProps.value === "string" &&
            (restOfTextFieldProps.value?.includes("e") ||
              restOfTextFieldProps.value?.includes("")) && {
              shrink: true,
            })}
          /*  */
        >
          {label}
        </InputLabel>
        <Input
          className="text-base text-hb_blue-800"
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          variant={variant}
          fullWidth={fullWidth}
          {...restOfTextFieldProps}
          /* {...(!controlled && { inputRef })} */
          inputRef={inputRef}
          size={size}
          disabled={disabled}
          startAdornment={startAdornment}
          endAdornment={
            <>
              {type === "password" ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : endAdornment ? (
                endAdornment
              ) : null}
            </>
          }
        />
        <FormHelperText error={Boolean(error)}>{helperText}</FormHelperText>
      </FormControl>
    </ErrorBoundary>
  );
});

CustomTextField.defaultProps = {
  label: "",
  variant: "standard",
  ref: null,
  type: "text",
  error: false,
  helperText: "",
  className: null,
  inputRef: null,
  required: false,
  fullWidth: true,
  size: "medium",
  disabled: false,
  labelColor: "#000000CC",
  startAdornment: null,
  endAdornment: null,
};

export default CustomTextField;
