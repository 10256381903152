import { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Search as SearchIcon, Clear as ClearIcon } from "@mui/icons-material";

const TableSearch = ({ onChange, placeholder, fullWidth }) => {
  const clearSearch = () => {
    onChange("");
    setSearchText("");
  };
  const [searchText, setSearchText] = useState("");
  const handleSearchChange = (search) => {
    setSearchText(search);
    onChange(search);
  };

  return (
    <TextField
      placeholder={`Search ${placeholder}`}
      value={searchText}
      fullWidth
      onChange={(e) => handleSearchChange(e.target.value)}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        ...(searchText && {
          endAdornment: (
            <InputAdornment position="end" className="flex items-center">
              <ClearIcon onClick={clearSearch} className="cursor-pointer" />
            </InputAdornment>
          ),
        }),
      }}
      sx={{
        "& .MuiInput-root": {
          background: "white",
          borderRadius: "32px",
          padding: ".5rem",
        },
      }}
    />
  );
};

export default TableSearch;
