import { Grid } from "@mui/material";
import Header from "components/Header";
import SideNavDrawer from "components/SideNavDrawer";
import "../../App.scss";

const Layout = ({ children }) => {
  return (
    <div className="grid-container overflow-x-auto">
      <div className="grid-header">
        <Header />
      </div>
      <div className="grid-sidebar">
        <SideNavDrawer />
      </div>

      <div className="grid-content mt-[70px]  overflow-x-hidden overflow-x-hidden px-8">
        {children}
      </div>
    </div>
  );
};

export default Layout;
