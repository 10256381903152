import { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import SVG from "react-inlinesvg";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import TableSearch from "./TableSearch";
import { useDebounce } from "utils/CustomHooks";
import {
  Button,
  Grid,
  List,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
export default function DataTableCustom(props) {
  const {
    data,
    columns,
    setSearchHandler,
    placeholder,
    dropdownPageSize,
    setDropdownPageSize,
    setFiltersHandler,
    currentPage,
    pageChangeHandler,
    paginationProps,
    initialStateSortBy,
    buttonText,
    noHeader,
  } = props;

  //pagination variables
  let total_count = paginationProps?.total_count;
  let per_page = paginationProps?.per_page;

  //states
  const [searchKeyword, setSearchKeyword] = useState(null);

  //handlers
  const handleSearchUpdate = (text) => {
    setSearchKeyword(text?.trim());
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: initialStateSortBy || [],
      },
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      disableSortRemove: true,
    },
    useSortBy,
    usePagination
  );
  const {
    setPageSize,
    state: { sortBy },
  } = tableInstance;

  //useEffects
  useEffect(() => {
    if (setFiltersHandler) setFiltersHandler(sortBy);
  }, [sortBy, setFiltersHandler]);

  /* Debouncing the search */
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);

  useEffect(() => {
    if (setSearchHandler) setSearchHandler(debouncedSearchKeyword);
  }, [debouncedSearchKeyword, setSearchHandler]);
  useEffect(() => {
    // Set the page size
    if (paginationProps) {
      setPageSize(Math.floor(total_count / dropdownPageSize) || 1);
    }
  }, [paginationProps, setPageSize, total_count, dropdownPageSize]);

  return (
    <div className="w-full overflow-auto">
      <div className="my-6 overflow-auto">
        {noHeader || (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <TableSearch
                placeholder={placeholder || "User"}
                value={debouncedSearchKeyword}
                onChange={handleSearchUpdate}
              />
            </Grid>
            {buttonText && (
              <Grid item xs={2}>
                <Button
                  fullWidth
                  color="primary"
                  className="!bg-[rgb(26, 46, 70)]"
                  sx={{ backgroundColor: "rgb(26, 46, 70) !important" }}
                  variant="contained"
                >
                  {buttonText}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </div>
      <div className="overflow-x-auto rounded-t-[10px] bg-pwave-main py-2">
        <table
          {...tableInstance.getTableProps()}
          className="table w-full border-collapse"
        >
          <thead className="text-semibold px-2 align-middle">
            {
              // Loop over the header rows
              tableInstance.headerGroups?.map((headerGroup) => (
                // Apply the header row props
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="text-saboo_grey-500 	align-middle text-base font-semibold capitalize tracking-normal"
                >
                  {
                    // Loop over the headers in each row
                    headerGroup?.headers?.map((column, idx) => (
                      // Apply the header cell props

                      <th className="	py-4  px-2 text-left">
                        <div
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({
                              style: {
                                width: column.width || "100px",
                              },
                            })
                          )}
                          className="flex items-center space-x-2"
                        >
                          <span
                            className={` text-sm font-medium text-white ${
                              column.isSorted ? "sorted" : null
                            } align-middle`}
                          >
                            {
                              // Render the header
                              column.render("Header")
                            }
                          </span>
                          <span className={`align-middle`}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="sorted-svg-icon flex">
                                  <FaSortAmountDown className="text-hb_orange-dark" />
                                </span>
                              ) : (
                                <span className="sorted-svg-icon">
                                  <FaSortAmountUp className="text-hb_orange-dark" />
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <div
                          style={
                            idx === 0
                              ? { width: "50px" }
                              : {
                                  width: column.width || "100px",
                                }
                          }
                        >
                          {column.Filter ? column.render("Filter") : null}
                        </div>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>

          <tbody {...tableInstance.getTableBodyProps()}>
            {
              // Loop over the table rows
              tableInstance?.rows?.map((row) => {
                // Prepare the row for display
                tableInstance.prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()} className="border-b px-2">
                    {
                      // Loop over the rows cells
                      row?.cells?.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`bg-white text-sm text-hb_black-100 ${
                              cell.column.isSorted ? "sorted" : "not-sorted"
                            }  py-4 pl-3 text-left  align-middle`}
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
      <div className="-py-2 z-0 flex items-center justify-center rounded-b-lg bg-white px-4">
        <Select
          className="pagination-select mr-4"
          value={dropdownPageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value) || 1);
            setDropdownPageSize(Number(e.target.value));
          }}
          defaultValue={10}
          IconComponent={KeyboardArrowDownOutlined}
          sx={{
            "& .MuiSelect-select": {
              paddingBlock: ".6rem",
            },
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>

        <List>
          <Pagination
            count={Math.ceil(total_count / per_page) || 0}
            shape="rounded"
            showFirstButton
            showLastButton
            page={currentPage || 1}
            onChange={(e, value) => pageChangeHandler(value)}
          />
        </List>
      </div>
    </div>
  );
}
