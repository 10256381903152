import React from "react";
import { DialogTitle, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Styles from "./CommonDialog.module.scss";
import CloseIcon from "@mui/icons-material/Close";

const StyledCommonDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  margin: "0rem 0rem 1.5rem 0rem",
  justifyContent: "space-between",
  alignItems: "center",
  textAlign: "center",
  width: "100%",
  padding: theme.spacing(1, 0),
}));

const CommonDialogTitle = (props) => {
  const { onClose, title } = props;

  return (
    <div>
      <Typography
        align="center"
        className=" pt-5 pb-2 text-[18px] font-medium text-pwave-main"
      >
        {title}
      </Typography>
    </div>
  );
};

export default CommonDialogTitle;

CommonDialogTitle.defaultProps = {
  onClose: () => null,
};
