import user from "assets/Icons/dashboard/user.svg";
import subscription from "assets/Icons/dashboard/subscription.svg";
import video from "assets/Icons/dashboard/video.svg";

const RouteConstants = {
  REDIRECTOR: "/",
  LOGIN: "/login",
  MY_PROFILE: "/my-profile",
  USER: "/user",
  VIDEO: "/video",
  SUBSCRIPTION: "/subscription",
};

export const NavigationLinks = [
  {
    name: "User",
    redirectLink: RouteConstants.REDIRECTOR,
    img: user,
  },

  {
    name: "Video",
    redirectLink: RouteConstants.VIDEO,
    img: video,
  },
  {
    name: "Subscription",
    redirectLink: RouteConstants.SUBSCRIPTION,
    img: subscription,
  },
];

export default RouteConstants;
