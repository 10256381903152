import { lazy, Suspense } from "react";
import { Route, Routes, Outlet, Navigate, useLocation } from "react-router-dom";
import RouteConstants from "./RouteConstants";
import { Backdrop, CircularProgress } from "@mui/material";
import { useIsFetching, useIsMutating } from "react-query";
import { Layout } from "components";
import VideoPage from "pages/Video";
import Subscription from "pages/Subscription";
import Profile from "pages/Profile";

/* Login */
const Login = lazy(() => import("pages/Login"));

/* Dashboard */
const Dashboard = lazy(() => import("pages/Dashboard"));

const NotFound404 = lazy(() => import("pages/404"));

const Index = () => {
  const runningAPICount = useIsFetching();
  const mutatingAPICount = useIsMutating();

  return (
    <>
      <div>
        <Suspense fallback={<Backdrop open={true} />}>
          <Routes>
            <Route path={RouteConstants.LOGIN} element={<Login />} />

            <Route element={<ProtectedRoutes />}>
              {/* Dashboard */}
              <Route path={"/"} element={<Dashboard />} />
              <Route path={RouteConstants.DASHBOARD} element={<Dashboard />} />
              <Route path={RouteConstants.VIDEO} element={<VideoPage />} />
              {/* <Route path={RouteConstants.MY_PROFILE} element={<Profile />} /> */}
              <Route
                path={RouteConstants.SUBSCRIPTION}
                element={<Subscription />}
              />

              {/* User Management */}
            </Route>
            <Route path="*" element={<NotFound404 />} />
          </Routes>
        </Suspense>
        <Backdrop
          open={Boolean(runningAPICount || mutatingAPICount)}
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 100,
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};

const ProtectedRoutes = () => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("token");
  return isLoggedIn ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={RouteConstants.LOGIN} replace state={{ from: location }} />
  );
};

export default Index;
