import { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemSecondaryAction,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { NavigationLinks } from "routes/RouteConstants";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";

const SideNavDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  //states
  const [navItemSelected, setNavItemSelected] = useState([]);
  const [navItemExpanded, setnavItemExpanded] = useState([]);

  //handlers
  const handleParentNavItemClick = (NavigationItem) => {
    if ("children" in NavigationItem) {
      if (navItemExpanded.includes(NavigationItem.name)) {
        setnavItemExpanded(
          navItemExpanded.filter(
            (navListItems) => navListItems !== NavigationItem.name
          )
        );
      } else {
        setnavItemExpanded([...navItemExpanded, NavigationItem.name]);
      }
    } else {
      navigate(NavigationItem.redirectLink);

      setNavItemSelected(NavigationItem.name);
    }
  };
  const handleChildNavItemClick = (
    NavigationItem,
    NavigationParentItem,
    pushHistory = false
  ) => {
    if (pushHistory) {
      if ("redirectLocation" in NavigationItem) {
        navigate(NavigationItem.redirectLocation());
      } else {
        navigate(NavigationItem.redirectLink);
      }
    }
    setNavItemSelected([NavigationItem.name, NavigationParentItem.name]);
  };

  //useEffects
  useEffect(() => {
    if (location.pathname) {
      const path = location.pathname;
      const navItem = NavigationLinks.find((NavigationItem) => {
        if (NavigationItem.children) {
          return NavigationItem.children.find(
            (child) => child.redirectLink === path
          );
        }
        return NavigationItem.redirectLink === path;
      });

      if (navItem?.name) {
        setNavItemSelected(navItem.name);
      }
    }
    /* if (Array.isArray(NavigationLinks) && NavigationLinks.length > 0) {
      setNavItemSelected(NavigationLinks[0].name);
    } */
  }, []);

  const displayDrawerLinks = (NavigationItem, NavigationIndex) => {
    const isItemSelected = navItemSelected.includes(NavigationItem.name);

    return (
      <div key={`${NavigationItem}${NavigationIndex}`}>
        <ListItemButton
          key={NavigationItem.name}
          selected={isItemSelected}
          onClick={() => handleParentNavItemClick(NavigationItem)}
        >
          <div
            className={`relative -left-3 h-8 w-1 rounded-r-xl ${
              isItemSelected ? "#fff" : "bg-transparent"
            }`}
          ></div>
          <div className="flex w-full items-center justify-between">
            <img
              style={{ marginRight: "20px" }}
              width={NavigationItem.name === "User" ? 23 : 20}
              src={NavigationItem.img}
            />

            <ListItemText
              primary={
                <span className="text-[15px] !font-medium !text-[#fff]">
                  {NavigationItem.name}
                </span>
              }
              sx={{ color: "#fff" }}
            />
          </div>
        </ListItemButton>
        {"children" in NavigationItem &&
          navItemExpanded.includes(NavigationItem.name) && (
            <List component="div" disablePadding className="list-decimal">
              {NavigationItem.children.map((NavigationChildrenItem) =>
                "redirectLink" in NavigationChildrenItem ? (
                  <NavLink
                    to={
                      "redirectLink" in NavigationChildrenItem &&
                      NavigationChildrenItem.redirectLink
                    }
                    key={NavigationChildrenItem.name}
                  >
                    <ListItemButton
                      selected={navItemSelected.includes(
                        NavigationChildrenItem.name
                      )}
                      onClick={() =>
                        handleChildNavItemClick(
                          NavigationChildrenItem,
                          NavigationItem
                        )
                      }
                    >
                      <ListItemText
                        primary={
                          <span className="!text-[14px] !text-white">
                            NavigationChildrenItem.name
                          </span>
                        }
                        className="ml-6 !text-[#fff]"
                      />
                    </ListItemButton>
                  </NavLink>
                ) : (
                  <ListItemButton
                    key={NavigationChildrenItem.name}
                    selected={navItemSelected.includes(
                      NavigationChildrenItem.name
                    )}
                    onClick={() =>
                      handleChildNavItemClick(
                        NavigationChildrenItem,
                        NavigationItem,
                        true
                      )
                    }
                  >
                    <ListItemText
                      primary={NavigationChildrenItem.name}
                      className="ml-6"
                    />
                  </ListItemButton>
                )
              )}
            </List>
          )}
      </div>
    );
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: 240,
        "& .MuiDrawer-paper": {
          width: 240,
        },

        flexShrink: 0,
        "& .MuiPaper-root": {
          marginTop: "4rem",
          borderTopRightRadius: "30px",
          borderBottomRightRadius: "40px",
          filter:
            "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
        },
      }}
    >
      <List sx={{ backgroundColor: "#102040", color: "#fff", height: "100vh" }}>
        <div className="mt-[45px]">
          {NavigationLinks?.map(displayDrawerLinks)}
        </div>
      </List>
    </Drawer>
  );
};

export default SideNavDrawer;
