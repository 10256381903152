import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Badge,
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from "@mui/material";

import {
  FetchUserDetailsQuery,
  GetNotificationsQuery,
  LogoutUserQuery,
  ReadNotificationQuery,
} from "pages/Login/LoginQueries";
import RouteConstants from "routes/RouteConstants";
import logoutImage from "assets/Images/logut.svg";
import Profile from "pages/Profile";
import CommonDialog from "components/CommonDialog";
import PWaveLogoImg from "assets/Images/pWave.png";

const Header = () => {
  const navigate = useNavigate();
  const [openProfile, setOpenProfile] = useState(false);

  //states

  //queries
  const { data: userData } = FetchUserDetailsQuery();
  const { mutate: logoutUser, isSuccess: logoutSuccess } = LogoutUserQuery();

  //handlers
  const handleLogout = (event) => {
    localStorage.clear();
    navigate("/login");
  };
  const handleCloseProfile = () => {
    setOpenProfile(false);
  };

  //useEffects

  return (
    <header
      className="flex justify-between bg-white px-8"
      style={{
        boxShadow: "0px 4px 12px #455E7C0D",
        position: "fixed",
        top: 0,
        zIndex: 100,
        width: "100%",

        height: 65,
      }}
    >
      {/* <h1 className="flex  items-center	text-hb_blue-500 font-bold text-lg">
        LOGO
      </h1> */}
      <div className="flex cursor-pointer items-center">
        <div className="w-[100px] px-4">
          <img src={PWaveLogoImg} alt="p-wave logo" />
        </div>
      </div>

      <div className="flex items-center justify-between gap-4">
        <div>
          <IconButton id="fade-button" size="small" style={{ color: "black" }}>
            <Box sx={{ color: "action.active" }} onClick={handleLogout}>
              <img src={logoutImage} alt="logout" width={25} />
            </Box>
          </IconButton>
        </div>

        <IconButton size="small" onClick={() => setOpenProfile(true)}>
          <Avatar
            src={userData?.data?.avatar || ""}
            className="my-auto flex items-center"
            sx={{ width: 30, height: 30 }}
          />
        </IconButton>

        <span></span>
      </div>
      <CommonDialog
        maxWidth={"md"}
        title={"Admin Profile"}
        onClose={handleCloseProfile}
        open={openProfile}
      >
        <Profile onClose={handleCloseProfile} />
      </CommonDialog>
    </header>
  );
};

export default Header;
