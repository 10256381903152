import { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Paper, Box, Button, IconButton } from "@mui/material";
import SearchIcon from "assets/Icons/Group 61.svg";
import { DataTableCustom } from "components";
import { useLocalStorage } from "utils/CustomHooks";
import { useNavigate } from "react-router-dom";
import RouteConstants from "routes/RouteConstants";
import { FetchUserDataQuery } from "../Dashboard/DashboardQueries";

function Subscription() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#264265",

    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: "white",
    borderRadius: "10px",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0 30px 0 0",
  }));
  const { data: userList } = FetchUserDataQuery();
  const [fetchCoursesPayload, setFetchCoursesPayload] = useState(null);
  const [search, setSearch] = useState(null);
  const [currentPage, setCurrentPage] = useLocalStorage(
    "coursesList_currentPage",
    1
  );
  const [dropdownPageSize, setDropdownPageSize] = useLocalStorage(
    "coursesList_dropdownPageSize",
    50
  );
  const setDropdownPageSizeHandler = (count) => {
    setCurrentPage(1);
    setDropdownPageSize(count);
  };
  const pageChangeHandler = (pageNo) => {
    setCurrentPage(pageNo);
  };
  const setSearchHandler = (searchKeyword) => {
    setSearch(searchKeyword);
  };
  const data = useMemo(() => userList?.data.results || [], [userList]);
  const columns = useMemo(
    () => [
      {
        Header: "Daily Prompts",
        accessor: "name",
        width: "200px",
      },

      {
        Header: "Email Id",
        accessor: "email",
        width: "200px",
        disableSortBy: true,
      },

      {
        Header: "Subscription due",
        accessor: (d) => "N/A",

        width: "150px",
        disableSortBy: true,
      },
      {
        Header: "Active/Inactive",
        accessor: (d) => {
          if (d.is_active) {
            return <span className="text-green-500">Active</span>;
          } else {
            return <span className="text-red-500">Inactive</span>;
          }
        },

        width: "150px",
        disableSortBy: true,
      },
    ],
    []
  );

  useEffect(() => {
    setFetchCoursesPayload({
      page: 1,
      per_page: dropdownPageSize,
      search,
    });
  }, [dropdownPageSize]);
  useEffect(() => {
    setFetchCoursesPayload({
      page: currentPage,
      per_page: dropdownPageSize,
      search,
    });
  }, [currentPage]);
  useEffect(() => {
    if (search || search === "") {
      setCurrentPage(1);
      setFetchCoursesPayload({
        page: 1,
        per_page: dropdownPageSize,
        search,
      });
    }
  }, [search]);
  return (
    <div>
      <Grid container item>
        <DataTableCustom
          noHeader
          dropdownPageSize={dropdownPageSize}
          setDropdownPageSize={setDropdownPageSizeHandler}
          currentPage={currentPage}
          pageChangeHandler={pageChangeHandler}
          setSearchHandler={setSearchHandler}
          buttonText="Create User"
          data={data}
          columns={columns}
        />
      </Grid>
    </div>
  );
}

export default Subscription;
