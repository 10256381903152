import { useAPI } from "services";
import { useQuery } from "react-query";
import { useToast } from "utils/CustomHooks";
import { useMutation, useQueryClient } from "react-query";

const FetchUserData = (body) => {
  console.log(body);
  const method = "GET";
  let url = `api/users/lists?`;
  if (body?.search) {
    url += `search=${body?.search}`;
  }
  if (body?.sorts?.[0]?.desc) {
    url += `&order=desc`;
  } else {
    url += `&order=asc`;
  }
  if (body?.sorts?.[0]?.id) {
    url += `&field=${body?.sorts?.[0]?.id}`;
  }
  url += `&page=${body?.page || 1}&per_page=${body?.per_page || 10}`;
  return useAPI({ method, url });
};

export const FetchUserDataQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useQuery("USER_DATA", () => FetchUserData(payload), {
    enabled: true,
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

const ErrorHandler = (error) => {
  const responseToast = useToast();
  if (error?.errors) {
    responseToast.toastError("Something went wrong!", error.errors);
  } else if (error?.message) {
    responseToast.toastError(error.message);
  }
};
