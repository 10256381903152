import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useToast } from "utils/CustomHooks";

import { useAPI } from "services";

const FetchVideos = () => {
  const method = "GET";
  const url = `api/videos`;
  return useAPI({ method, url });
};

export const FetchVideosAPI = () => {
  const navigate = useNavigate();
  return useQuery("GET_USER_VIDEOS", FetchVideos, {
    enabled: true,
  });
};
const PostVideos = (body) => {
  const method = "POST";
  const url = `api/videos/add`;
  return useAPI({ method, url, body });
};

export const PostVideosAPI = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation("POST_VIDEO", PostVideos, {
    enabled: true,
    onSuccess: () => {
      queryClient.invalidateQueries();
      responseToast.toastSuccess("Video added Successfully");
    },
    onError: () => {
      responseToast.toastError("Error while adding Video");
    },
  });
};
const DeleteVideos = (id) => {
  console.log(id);
  const method = "DELETE";
  const url = `api/videos/${id}`;
  return useAPI({ method, url });
};

export const DeleteVideosAPI = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(DeleteVideos, {
    enabled: true,
    onSuccess: () => {
      queryClient.invalidateQueries();
      responseToast.toastSuccess("Video deleted Successfully");
    },
    onError: () => {
      responseToast.toastError("Error while deleting Video");
    },
  });
};
