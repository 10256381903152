import { Avatar, Button } from "@mui/material";
import { CustomTextField } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React from "react";
import {
  FetchUserDetailsQuery,
  UpdateProfileQuery,
} from "pages/Login/LoginQueries";
const schema = yup.object().shape({
  name: yup
    .string()
    .required("Name is a required field")
    .min(2, "Name length must be min of 2 alphabets")
    .max(26, "Name length must be max of 26 alphabets")
    .matches(/^[aA-zZ\s]+$/, "Please enter a Valid Name"),
  email: yup
    .string()
    .required("Email is a required field")
    .email("Please enter a valid email"),
});

const Profile = ({ onClose }) => {
  const { data: userData } = FetchUserDetailsQuery();
  const { mutate: updateProfile } = UpdateProfileQuery();
  const {
    register,
    handleSubmit,
    watch: loginFormCredentials,
    formState: { errors },
    clearErrors,
    setFocus,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: userData?.data?.user?.email,
      name: userData?.data?.user?.name,
    },
  });

  const { ref: refRegisterName, ...RegisterLoginName } = register("name");
  const { ref: refRegisterEmail, ...RegisterLoginEmail } = register("email");
  const handleEditProfile = (data) => {
    updateProfile(data, { onSuccess: () => onClose() });
  };
  return (
    <form onSubmit={handleSubmit(handleEditProfile)}>
      <div className="mt-[60px] flex flex-col justify-center">
        <div className=" flex flex-col items-center justify-center border  px-2 py-4">
          <div className="-mt-[60px] flex flex-col items-center justify-center gap-4 rounded-sm bg-[#E5E5E5] py-4 px-8 shadow-md">
            <p>Profile</p>
            <Avatar stc={''} sx={{ bgcolor: " #102040" }} alt={userData?.data?.user?.name} />
           
            <p>{userData?.data?.user?.name}</p>
          </div>
          <div className="mt-8 flex w-[400px] flex-col items-center justify-center">
            <CustomTextField
              required
              label="Email"
              type="email"
              variant="outlined"
              inputRef={refRegisterEmail}
              {...RegisterLoginEmail}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              autoComplete="email"
            />
            <CustomTextField
              required
              variant="outlined"
              label="Name"
              type="text"
              inputRef={refRegisterName}
              {...RegisterLoginName}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              autoComplete="name"
            />
          </div>
        </div>
        <div className="mx-auto  mt-8 flex w-[400px] items-center justify-between">
          <Button
            color="primary"
            className="!bg-[rgb(26, 46, 70)] !text-[#fff]"
            sx={{ backgroundColor: "rgb(26, 46, 70) !important" }}
            type="submit"
          >
            Update
          </Button>
          <Button onClick={onClose} varaint="outlined">
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Profile;
